import { ObjectId } from 'bson';
import app from '../realm-config';



/**
 * Generates a new ObjectId or returns an ObjectId from a given string.
 * @param {string} [idString] - An optional string to convert to an ObjectId.
 * @returns {ObjectId} - The new or converted ObjectId.
 */

export function getNewId(idString) {
  if (idString) {
    return new ObjectId(idString);
  }
  return new ObjectId();
}


/**
 * Generates a string representation of a new ObjectId.
 * @returns {string} - The string representation of the ObjectId.
 */
export function stringId() {
  return new ObjectId().toString();
}

/*
TYPES
note: 0 (or no type)
canvas: 1
*/


export function getUserId() {
  if (app.currentUser && app.currentUser.id) {
    return app.currentUser.id;
  } else {
    // Handle the case where the user ID is not available
    return null; // or any other appropriate value or action
  }
}
export function getUserMail() {
  if (app.currentUser ) {
    return app.currentUser.profile.email;
  } else {
    // Handle the case where the user ID is not available
    return null; // or any other appropriate value or action
  }
}


export function createNode(data = {}, extraProps = {}) {
  const currentDate = new Date();
  const userId = app.currentUser ? app.currentUser.id : null;

  return {
    _id: new ObjectId(),
    parentId: null,
    userId: userId,  // Using the safe check for userId
    sharedWith: null,
    title: `Node-${currentDate.getSeconds()}`,
    brief: '',
    nodeIndex: null,
    tags: null,
    links: null,
    createdAt: currentDate,
    updatedAt: currentDate,
    ...data,
    ...extraProps
  };
}

export function createBasicNode() {
  const currentDate = new Date();
  const userId = app.currentUser ? app.currentUser.id : null;
  return {
    _id: new ObjectId(),
    userId: userId,
    parentId: null,
    createdAt: currentDate,
    updatedAt: currentDate,
  };
}

export function cloneNode(node) {
  let newNode = {};
  const propertiesToPreserve = ['title', 'identity_UUIDProperty', 'type_TextProperty', 'userId']; // Add more properties to this array as needed

  // Preserve specified properties
  propertiesToPreserve.forEach(prop => {
    newNode[prop] = node[prop];
  });

  // Iterate over all properties in the original node
  Object.keys(node).forEach(key => {
    if (!propertiesToPreserve.includes(key)) { // Skip the properties listed to be preserved
      newNode[key] = null;
    }
  });

  newNode.title = newNode.title += " (clone)";
  newNode._id = new ObjectId();
  newNode.createdAt = new Date();
  newNode.updatedAt = new Date();
  newNode.locked = true;
  return newNode;

}

export function updateNode(node, data) {
  for (let key in data) {
    if (Object.prototype.hasOwnProperty.call(node, key) && key !== 'createdAt') {
      node[key] = data[key];
    }
  }
  node.updatedAt = new Date();
}

export function addDataByType(node, data, type) {
  node[type] = data;
}

//STORAGE

const defaultUserId = '645b5e00c7b61df466430901';

function nodesCollection() {
  return app.currentUser.mongoClient("mongodb-atlas").db("mainlistdb").collection("nodes");
}


export async function getRootCanvasTitlesAndIds(userId) {
  // Check if userId is valid
  if (!userId) {
    return null; // or throw new Error("Invalid userId");
  }

  try {
    const collection = nodesCollection(); // directly using the function

    // Use aggregation with $project stage for projection
    const pipeline = [
      {
        $match: {
          type: 1,
          userId: userId,
          parentId: { $in: [null, ""] }
        }
      },
      {
        $project: {
          _id: 1,  // Include _id
          title: 1,  // Include title
          //state: null  // Include title
        }
      }
    ];

    const nodesData = await collection.aggregate(pipeline);
    return nodesData;
  } catch (error) {
    console.error('Error in getRootCanvasTitlesAndIds:', error);
    return null;
  }
}

async function fetchNodesFromAPI(endpoint, params) {
  try {
    const response = await fetch(`https://data.mongodb-api.com/app/mainlistapp-kqoki/endpoint/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Response not OK:", await response.text());
      return [];
    }
  } catch (error) {
    console.error("Error calling API:", error);
    return [];
  }
}

export async function getNodesList(userId = '', customMatch = null, customProject = null) {
  console.log('getNodesList called with params:', { userId, customMatch, customProject });

  const params = {
    customMatch: customMatch || { identity: 'autodin' },
    customProject: customProject !== null 
        ? customProject // Use the provided customProject if it is not null
        : {
            _id: 1,
            title: 1,
            comments: 1,
            imageLink: 1,
            tarif: 1,
            motorisation: 1,
            capacity: 1,
            kms: 1,
            brand: 1,
            model: 1,
            date: 1,
            price: 1,
            updatedAt: 1,
            taxDeductible: 1,
            registration: 1,
            supplier: 1,
        }
};

  console.log('Constructed params:', params);

  try {
    const response = await fetchNodesFromAPI('getNodes', params);
    console.log('API response:', response);
    return response;
  } catch (error) {
    console.error('Error fetching nodes from API:', error);
    throw error;
  }
}


/**
 * Fetch a single node by its ObjectId.
 * @param {string|ObjectId} nodeId - The ID of the node to fetch.
 * @returns {object|null} The node if found, or null if not found.
 */
export async function getNode(nodeId) {
  if (!nodeId) {
    throw new Error('Node ID is required');
  }

  // Ensure that nodeId is an ObjectId
  const objectId = typeof nodeId === 'string' ? new ObjectId(nodeId) : nodeId;

  const customMatch = { _id: objectId }; // Ensure _id is an ObjectId
  const customProject = {}; // Empty object to project all fields

  try {
    const nodes = await getNodesList('', customMatch, customProject);
    if (nodes && nodes.length > 0) {
      return nodes[0]; // Return the first (and only) node
    } else {
      return null; // No node found with the given ID
    }
  } catch (error) {
    console.error('Error retrieving node by ID:', error);
    return null;
  }
}






/*
// Usage examples:
// Use default $match and $project
getNodesList(userId); /identical to getNodesTitlesAndIds

// Use custom $match and $project
const customMatch = { type: 1, userId: userId, parentId: null };
const customProject = { _id: 1, title: 1, brief: 1, imageLink: 1 };
getNodesList(userId, customMatch, customProject);

*/




export async function getNodesTitlesAndIds(type, identity) {
  // Check if type or identity is null or undefined
  if (type == null || identity == null) {
    console.log('Type or Identity is null or undefined, returning null.');
    return [];
  }

  try {
    const collection = nodesCollection(); // Directly using the function

    const pipeline = [
      {
        $match: {
          identity_UUIDProperty: identity,
          type_TextProperty: {
            $regex: `^${type}`,  // Match type_TextProperty starting with the 'type' string
            $options: 'i'  // Case-insensitive matching
          }
        }
      },
      {
        $project: {
          _id: 1,  // Include _id
          title: 1  // Include title
        }
      }
    ];

    const nodesData = await collection.aggregate(pipeline);
    return nodesData;
  } catch (error) {
    console.error('Error in getNodesTitlesAndIds:', error);
    return null;
  }
}


export async function getRootCanvas(userId) {
  try {
    const query = {
      userId: userId,
      type: 1,  // canvas
      $or: [{ parentId: null }, { parentId: '' }]
    };
    return await nodesGet(query);
  } catch (error) {
    console.error('Error in getRootCanvas:', error);
    return null;
  }
}

export async function getUserNodes(userId = getUserId()) {
  try {
    // Verify if userId is obtained, otherwise, handle the case where no userId is available
    if (!userId) {
      console.error('No user ID available.');
      return null; // or any other appropriate action
    }

    const query = {
      userId: userId,
    };
    const nodes = await nodesGet(query);
    return await nodesGet(query);
  } catch (error) {
    console.error('Error in getUserNodes:', error);
    return null;
  }
}

export async function getCanvasById(canvasId) {
  try {
    const collection = nodesCollection();
    const canvas = await collection.findOne({ _id: new ObjectId(canvasId) });
    return canvas;
  } catch (error) {
    console.error('Error in getCanvasById:', error);
    return null;
  }
}

export async function nodePut(node) {
  // Ensure that _id is an ObjectId, if it exists
  if (node._id && !(node._id instanceof ObjectId)) {
      node._id = new ObjectId(node._id); // Convert _id to ObjectId if it is not already an ObjectId
  }

  // Ensure createdAt is a valid Date object
  if (node.createdAt && !(node.createdAt instanceof Date)) {
    node.createdAt = new Date(node.createdAt); // Convert to Date if it's not a Date object
  }

  // Ensure updatedAt is a valid Date object, or set it to the current date
  if (!node.updatedAt || !(node.updatedAt instanceof Date)) {
    node.updatedAt = new Date(); // Set updatedAt to the current date/time if it's missing or invalid
  }

  // Wrapping the node in an array for nodesPut
  const result = await nodesPut([node]);
  return result[0]; // Return the result of the first (and only) node operation
}

export async function nodeDelete(nodeId) {
  // Creating a query to match the node by _id
  const query = { _id: new ObjectId(nodeId) };
  return await nodesDelete(query);
}

export async function nodeGet(nodeId) {
  try {
    
    const collection = nodesCollection();
    const node = await collection.findOne({ _id: new ObjectId(nodeId) });
    return node;
  } catch (error) {
    console.error('Error in getNodeById:', error);
    return null;
  }
}

export async function nodesGet(query = {}) {
  try {
    const collection = nodesCollection(); // MongoDB Realm collection
    const nodes = await collection.find(query); // No .toArray() needed
    return nodes;
  } catch (error) {
    console.error('Error in nodesGet:', error);
    return null;
  }
}

export async function nodeGetOne(query = {}) {
  try {
    const collection = nodesCollection(); // MongoDB Realm collection
    const node = await collection.findOne(query); // Fetch the first matching node
    return node;
  } catch (error) {
    console.error('Error in nodesGet:', error);
    return null;
  }
}

export async function nodesPut(payload) {
  const collection = nodesCollection();  // Renamed to `collection`

  let results = [];

  for (const node of payload) {
    let existingNode = await collection.findOne({ _id: node._id });

    if (existingNode) {
      let result = await collection.updateOne(
        { _id: node._id },
        { $set: node }
      );
      results.push(result);
    } else {
      let result = await collection.insertOne(node);
      results.push(result);
    }
  }

  return results;
}

export async function nodesDelete(query = {}) {
  try {
    const collection = nodesCollection();
    const result = await collection.deleteMany(query);
    return result.deletedCount;
  } catch (error) {
    console.error('Error in nodesDelete:', error);
    return null;
  }
}

export async function fetchNodeByTitle(title, userId) {
  const collection = nodesCollection();
  try {
    const query = {
      title: title,
      userId: userId
    };
    const node = await collection.findOne(query);
    return node;
  } catch (error) {
    console.error('Error in getCanvasById:', error);
    return null;
  }
}

export async function uploadFileToServer(fileBase64, fileName) {
  // Endpoint URL
  const endpoint = 'https://data.mongodb-api.com/app/mainlistapp-kqoki/endpoint/fileUpload';

  // Prepare the payload
  const payload = {
    fileName: fileName,
    fileBase64: fileBase64
  };

  // Make the POST request
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error uploading file:', error);
    return { success: false, error: error.message };
  }
}

export async function deleteFileFromServer(fileName) {
  // Endpoint URL for the delete function
  const endpoint = 'https://data.mongodb-api.com/app/mainlistapp-kqoki/endpoint/deleteFile';

  // Prepare the payload
  const payload = {
    fileName: fileName
  };

  // Make the POST request
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error deleting file:', error);
    return { success: false, error: error.message };
  }
}
