import React, { useState, useEffect } from 'react';
import { Stack, FormControlLabel, Checkbox, TextField } from '@mui/material';
import { useMlCanvas } from '../../MlModels/MlCanvasProvider';

const MlCanVasSetup = () => {
    const {
        mlNode,
        setMlNode,
        getUserId
    } = useMlCanvas();

    const superuser = process.env.REACT_APP_SUPERUSER;
    
    const templateUserId = mlNode ? mlNode.userId : null;

    const [template, setTemplate] = useState(false);
    const [playBar, setPlayBar] = useState(false);
    const [publicTemplate, setPublicTemplate] = useState(false);
    const [imageLink, setImageLink] = useState('');
    const [brief, setBrief] = useState('');
    const [tags, setTags] = useState('');

    useEffect(() => {
        if (mlNode) {
            setTemplate(mlNode.template || false);
            setPublicTemplate(mlNode.publicTemplate || false);
            setImageLink(mlNode.imageLink || '');
            setBrief(mlNode.brief || '');
            setTags(mlNode.tags ? mlNode.tags.join(', ') : '');
        }
    }, [mlNode]);

    const handleTemplateChange = (event) => {
        const newValue = event.target.checked;
        setTemplate(newValue);
        setMlNode(prevNode => ({ ...prevNode, template: newValue }));
    };
    const handlePlayBarChange = (event) => {
        const newValue = event.target.checked;
        setPlayBar(newValue);
        setMlNode(prevNode => ({ ...prevNode, playBar: newValue }));
    };

    const handlePublicTemplateChange = (event) => {
        const newValue = event.target.checked;
        setPublicTemplate(newValue);
        setMlNode(prevNode => ({ ...prevNode, publicTemplate: newValue }));
    };

    const handleImageLinkChange = (event) => {
        const newValue = event.target.value;
        setImageLink(newValue);
        setMlNode(prevNode => ({ ...prevNode, imageLink: newValue }));
    };

    const handleBriefChange = (event) => {
        const newValue = event.target.value;
        setBrief(newValue);
        setMlNode(prevNode => ({ ...prevNode, brief: newValue }));
    };

    const handleTagsChange = (event) => {
        const newValue = event.target.value;
        setTags(newValue);
        setMlNode(prevNode => ({ ...prevNode, tags: newValue.split(',').map(tag => tag.trim()) }));
    };

    return (
        <Stack direction='column' sx={{ width: '100%', height: '100%' }} padding={1} spacing={3}>
            <Stack>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={template}
                            onChange={handleTemplateChange}
                            disabled={templateUserId !== getUserId()}
                        />
                    }
                    label="Template"
                />
                {getUserId() === superuser && (
                    <FormControlLabel
                        control={<Checkbox checked={publicTemplate} onChange={handlePublicTemplateChange} />}
                        label="Public Template"
                    />
                )}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={playBar}
                            onChange={handlePlayBarChange}
                            disabled={templateUserId !== getUserId()}
                        />
                    }
                    label="Display Play Bar"
                />
            </Stack>
            {templateUserId === getUserId() && (  // Conditionally render the Stack if the userId matches
                <Stack spacing={3}>
                    <TextField
                        label="Image Link"
                        value={imageLink}
                        onChange={handleImageLinkChange}
                        fullWidth
                    />
                    <TextField
                        label="Brief"
                        value={brief}
                        onChange={handleBriefChange}
                        multiline
                        rows={4}
                        fullWidth
                        sx={{ overflow: 'auto' }}
                    />
                    <TextField
                        label="Tags"
                        value={tags}
                        onChange={handleTagsChange}
                        multiline
                        rows={4}
                        fullWidth
                        helperText="Enter tags separated by commas"
                        sx={{ overflow: 'auto' }}
                    />
                </Stack>
            )}
        </Stack>
    );
}

export default MlCanVasSetup;
