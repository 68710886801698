import React, { useState, useEffect } from 'react';
import { List, Stack, Paper } from '@mui/material';
import { getNodesList } from '../../MlModels/MlNode';
import AutoDinCard from './AutoDinCard';
import { useAuth } from "../../AuthContext";
import { useUser } from '../../MlModels/UserContext'

const AutoDinList = ({ mt = 0, elevation = 0, onCardClick, refresh, searchEmbedding, selectedTab }) => {
    const { currentUser } = useAuth();
    const [nodes, setNodes] = useState([]);
    const [loading, setLoading] = useState(true);
    const { userData } = useUser();
    
    // Check if user is logged in
    const isLoggedIn = !!currentUser;

    // Only wait for userData when the user is logged in
    const isUserDataReady = isLoggedIn ? !!userData : true;

    const customMatch = isUserDataReady ? {
        identity: 'autodin', 
        ...(isLoggedIn && selectedTab === 0 && { 
            supplier: userData?.businessIdentity, 
            status: { $nin: ['Public', 'Archive'] } // Show vehicles in "Edition" (not Public, not Archive)
        }),
        ...(isLoggedIn && selectedTab === 1 && { supplier: userData?.businessIdentity, status: 'Archive' }), // Show "Archive" vehicles
        ...(isLoggedIn && selectedTab === 2 && { supplier: userData?.businessIdentity, status: 'Public' }), // Show "Public" vehicles for the logged-in user's business
        ...(selectedTab === 3 && { status: 'Public' }), // Show all Public vehicles
        ...(searchEmbedding && {
            vectorSearch: {
                index: 'vector_index',
                path: 'embedding',
                queryVector: searchEmbedding,
                numCandidates: 50,
                limit: 5
            }
        })
    } : {
        identity: 'autodin',
        status: 'Public', // Show Public vehicles if not logged in
        ...(searchEmbedding && {
            vectorSearch: {
                index: 'vector_index',
                path: 'embedding',
                queryVector: searchEmbedding,
                numCandidates: 50,
                limit: 5
            }
        })
    };

    // Fetch nodes when refresh, searchEmbedding, or selectedTab changes, and when userData is ready
    useEffect(() => {
        const fetchNodes = async () => {
            setLoading(true);
            try {
                // Fetch the nodes based on the customMatch filter
                const nodesList = await getNodesList(currentUser?.uid, customMatch, null);
                console.log('Fetched nodes:', nodesList);
        
                // Sort the nodes based on the selected tab (e.g., sort by updatedAt for relevant tabs)
                let sortedNodes = nodesList;
                if ([0, 1, 2].includes(selectedTab) && isLoggedIn) { // Sort for Edition, Archive, Team if logged in
                    sortedNodes = [...nodesList].sort((a, b) => {
                        const dateA = new Date(a.updatedAt).getTime();  // Convert to timestamp
                        const dateB = new Date(b.updatedAt).getTime();  // Convert to timestamp
                        
                        // Sort in descending order (newest on top)
                        return dateB - dateA;
                    });
                }
                setNodes(sortedNodes); // Set the sorted nodes in state
            } catch (error) {
                console.error('Failed to fetch nodes:', error);
            } finally {
                setLoading(false);
            }
        };
    
        if (isUserDataReady) {
            fetchNodes();
        }
    }, [refresh, searchEmbedding, selectedTab, isUserDataReady]);

    return (
        <Paper
            sx={{ width: '100%', bgcolor: 'rgb(250,250,250)', mt: { mt }, borderRadius: '0px' }}
            elevation={elevation}
        >
            <Stack direction="column" width="100%" maxHeight="100%" overflow="auto" padding={0}>
                <Stack
                    direction="column"
                    width="100%"
                    maxHeight="100%"
                    overflow="auto"
                >
                    <List sx={{ width: '100%', bgcolor: 'rgb(250,250,250)' }}>
                        {nodes.map((node) => (
                            <AutoDinCard key={node._id.toString()} node={node} onCardClick={onCardClick} />
                        ))}
                    </List>
                </Stack>
            </Stack>
        </Paper>
    );
};

export default AutoDinList;
